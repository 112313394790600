import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import _, { isEmpty, } from "lodash";
import TableCard from "pages/Marketpulse/TableCard";
import { TdStockDataHistory } from "services/api/api-service"
import CardSlider from "../Marketpulse/CardSlider";
const SwingSpectrum = (props) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        TdStockDataHistory()
            .then(result => {
                if (result && typeof result === 'object') {
                    // Process Data and Add AI_SIGNAL
                    console.log("Raw Result:", result);
                    const processedData = result.filter(stock => stock.day50 && stock.day50.length >= 49).map(stock => {
                        const avgHigh6 = (Number(stock.day50[0].HIGH) + Number(stock.day50[1].HIGH) + Number(stock.day50[2].HIGH) +
                            Number(stock.day50[3].HIGH) + Number(stock.day50[4].HIGH) + Number(stock.day50[5].HIGH)) / 6;
                        const lastTradePrice = Number(stock.LASTTRADEPRICE);
                        const avgTradePrice = Number(stock.AVERAGETRADEDPRICE);
                        const changePercent = Number(stock.PRICECHANGEPERCENTAGE);
                        // Define AI-based Signal
                        let AI_SIGNAL = "HOLD"; // Default
                        if (lastTradePrice > avgHigh6 && changePercent > 1) {
                            AI_SIGNAL = "BUY";
                        } else if (lastTradePrice < avgTradePrice && changePercent < -1) {
                            AI_SIGNAL = "SELL";
                        }
                        const high10 = Math.max(...stock.day50.slice(0, 10).map(day => Number(day.HIGH)));
                        const low10 = Math.min(...stock.day50.slice(0, 10).map(day => Number(day.LOW)));
                        let SINGNAL_10 = "HOLD"; // Default
                        if (lastTradePrice > high10) {
                            SINGNAL_10 = "BUY"; // Breakout above 10-day high
                        } else if (lastTradePrice < low10) {
                            SINGNAL_10 = "SELL"; // Breakdown below 10-day low
                        }
                        const high50 = Math.max(...stock.day50.slice(0, 49).map(day => Number(day.HIGH)));
                        const low50 = Math.min(...stock.day50.slice(0, 19).map(day => Number(day.LOW)));
                        let SINGNAL_50 = "HOLD"; // Default
                        if (lastTradePrice > high50) {
                            SINGNAL_50 = "BUY"; // Breakout above 10-day high
                        } else if (lastTradePrice < low50) {
                            SINGNAL_50 = "SELL"; // Breakdown below 10-day low
                        }
                        const high7 = Math.max(...stock.day50.slice(0, 7).map(day => Number(day.HIGH)));
                        const low7 = Math.min(...stock.day50.slice(0, 7).map(day => Number(day.LOW)));
                        let SINGNAL_7 = "HOLD"; // Default
                        if (lastTradePrice > high7) {
                            SINGNAL_7 = "BUY"; // Breakout above 10-day high
                        } else if (lastTradePrice < low7) {
                            SINGNAL_7 = "SELL"; // Breakdown below 10-day low
                        }
                        const high20 = Math.max(...stock.day50.slice(0, 20).map(day => Number(day.HIGH)));
                        const low20 = Math.min(...stock.day50.slice(0, 20).map(day => Number(day.LOW)));
                        let SINGNAL_20 = "HOLD"; // Default
                        if (lastTradePrice > high20) {
                            SINGNAL_20 = "BUY"; // Breakout above 10-day high
                        } else if (lastTradePrice < low20) {
                            SINGNAL_20 = "SELL"; // Breakdown below 10-day low
                        }
                        return {
                            ...stock,
                            AI_SIGNAL,
                            CHANGE_PERCENT: changePercent,
                            SINGNAL_10,
                            SINGNAL_50,
                            SINGNAL_7,
                            SINGNAL_20
                        };
                    });

                    setData(processedData);
                    console.log("Processed Result:", processedData);
                } else {
                    console.error("Invalid data format:", result);
                }
            })
            .catch(error => console.error("Error fetching stock data:", error));
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="card mb-0">
                        <div className="card-body p-0">
                            <div className="tradingview-widget-container h-0" id="tradingview-widget">
                                <div className="tradingview-widget-container__widget"></div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-0">
                        <div className="card-body px-0">
                            <div className="fs-1 fw-bold text-gradient"> Swing Radar</div>
                        </div>
                    </div>
                    {!isEmpty(data) &&
                        <Row>
                            <Col md={12} className="hideOnDesktop mb-3">
                                <CardSlider
                                    list={data.sort((a, b) => a.SortValue - b.SortValue)}
                                    type={'highPowered'}
                                    header={"Momentum Movers"}
                                />
                            </Col>
                            <Col md={12} className="hideOnDesktop mb-3">
                                <CardSlider list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.SINGNAL_10,
                                })).sort((a, b) => b.CHANGE_PERCENT - a.CHANGE_PERCENT)} type={'highPowerd'} header={"Turbo Trades"} />
                            </Col>
                            <Col md={12} className="hideOnDesktop mb-3">
                                <CardSlider list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.SINGNAL_50,
                                })).sort((a, b) => b.CHANGE_PERCENT - a.CHANGE_PERCENT)} type={'highPowerd'} header={"Bull Force"} />
                            </Col>
                            <Col md={12} className="hideOnDesktop mb-3">
                                <CardSlider list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.SINGNAL_50,
                                })).sort((a, b) => b.CHANGE_PERCENT - a.CHANGE_PERCENT)} type={'highPowerd'} header={"Bear Force"} />
                            </Col>
                            <Col md={12} className="hideOnDesktop mb-3">
                                <CardSlider list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.SINGNAL_50,
                                })).sort((a, b) => b.CHANGE_PERCENT - a.CHANGE_PERCENT)} type={'highPowerd'} header={"wealth Winners"} />
                            </Col>
                            <Col md={12} className="hideOnDesktop mb-3">
                                <CardSlider list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.SINGNAL_50,
                                })).sort((a, b) => b.CHANGE_PERCENT - a.CHANGE_PERCENT)} type={'highPowerd'} header={"wealth Loosers"} />
                            </Col>
                        </Row>
                    }
                    {!isEmpty(data) &&
                        <Row>
                            <Col md={6} id="right" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.SINGNAL_10,
                                })).sort((a, b) => b.CHANGE_PERCENT - a.CHANGE_PERCENT)} type={'highPowerd'} header={"10 Day BO Rally"} tableId={'pow1'} />
                            </Col>
                            <Col md={6} id="left" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.SINGNAL_50,
                                })).sort((a, b) => b.CHANGE_PERCENT - a.CHANGE_PERCENT)} type={'highPowerd'} header={"50 DAY BO"} tableId={'pow2'} />
                            </Col>
                            <Col md={6} id="left1" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.SINGNAL_50,
                                })).sort((a, b) => b.CHANGE_PERCENT - a.CHANGE_PERCENT)} type={'highPowerd'} header={"50 Day BO Rally"} tableId={'pow3'} />
                            </Col>
                            <Col md={6} id="left3" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.SINGNAL_10,
                                })).sort((a, b) => b.CHANGE_PERCENT - a.CHANGE_PERCENT)} type={'highPowerd'} header={"Range Breakout"} tableId={'pow4'} />
                            </Col>
                            <Col md={6} id="left3" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.SINGNAL_7,
                                })).sort((a, b) => b.CHANGE_PERCENT - a.CHANGE_PERCENT)} type={'highPowerd'} header={"Squeeze 7"} tableId={'pow4'} />
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};

SwingSpectrum.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(SwingSpectrum);
