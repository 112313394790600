import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import _, { isEmpty } from "lodash";
import TableCard from "pages/Marketpulse/TableCard";
import MomentumSpike from "./MomentumSpike";
import { TdStockDataHistory } from "services/api/api-service"
const InsiderStrategy = (props) => {
    let [data, setData] = useState([]);
    useEffect(() => {
        document.title = "Insider Strategy | Trendsarthi";
    }, []);
    let [mergedData, setMergedData] = useState([
        ["Symbol", "Parent", "Price Change"],
        ["All Stocks", null, 0],
    ]);
    let [mergedData10, setMergedData10] = useState([
        ["Symbol", "Parent", "Price Change"],
        ["All Stocks", null, 0],
    ]);
    const calculateMomentumSpike = (stocks) => {
        return stocks.slice(2).map(stock => {
            const priceChange = stock.CLOSE - stock.OPEN;
            const volumeChange = stock.TOTALQTYTRADED - stock.LASTTRADEQTY;
            const turnoverChange = (stock.AVERAGETRADEDPRICE * stock.TOTALQTYTRADED) - (stock.LASTTRADEPRICE * stock.LASTTRADEQTY);

            const momentumScore = (priceChange * 0.5) + (volumeChange * 0.3) + (turnoverChange * 0.2);

            return { ...stock, MOMENTUM_SCORE: momentumScore.toFixed(2) };
        }).sort((a, b) => b.MOMENTUM_SCORE - a.MOMENTUM_SCORE);
    };
    const calculateLOM = (stocks) => {
        return stocks.slice(2).map(stock => {
            const typicalPrice = (stock.HIGH + stock.LOW + stock.CLOSE) / 3;
            const vwap = (typicalPrice * stock.TOTALQTYTRADED) / stock.TOTALQTYTRADED;

            const priceChange = stock.CLOSE - stock.OPEN;
            const volumeChange = stock.TOTALQTYTRADED - stock.LASTTRADEQTY;
            const vwapDeviation = (stock.CLOSE - vwap) / vwap;

            const currentMomentum = (priceChange * 0.5) + (volumeChange * 0.3) + (vwapDeviation * 0.2);
            const lom = stock.PREVIOUS_MOMENTUM
                ? ((stock.PREVIOUS_MOMENTUM - currentMomentum) / stock.PREVIOUS_MOMENTUM) * 100
                : 0;
            return {
                ...stock,
                CHANGE_PERCENT: lom.toFixed(2),
            };
        }).sort((a, b) => b.CHANGE_PERCENT - a.CHANGE_PERCENT);
    };
    
    useEffect(() => {
        TdStockDataHistory()
            .then(result => {
                if (result && typeof result === 'object') {
                    // Process Data and Add AI_SIGNAL
                    const processedData = result.filter(stock => stock.day50 && stock.day50.length >= 49).map(stock => {
                        const avgHigh6 = (Number(stock.day50[0].HIGH) + Number(stock.day50[1].HIGH) + Number(stock.day50[2].HIGH) +
                            Number(stock.day50[3].HIGH) + Number(stock.day50[4].HIGH) + Number(stock.day50[5].HIGH)) / 6;

                        const lastTradePrice = Number(stock.LASTTRADEPRICE);
                        const avgTradePrice = Number(stock.AVERAGETRADEDPRICE);
                        const changePercent = Number(stock.PRICECHANGEPERCENTAGE);
                        // Define AI-based Signal
                        let AI_SIGNAL = "HOLD"; // Default
                        if (lastTradePrice > avgHigh6 && changePercent > 1) {
                            AI_SIGNAL = "BUY";
                        } else if (lastTradePrice < avgTradePrice && changePercent < -1) {
                            AI_SIGNAL = "SELL";
                        }
                        return {
                            ...stock,
                            AI_SIGNAL,
                            RSI: calculateRSI(stock),
                            RSI_SIGNAL: getRSISignal(stock),
                            REVERSAL_SIGNAL: calculateReversalSignal(stock),
                            CHANGE_PERCENT: calculateRSI(stock),
                            VOLUME_SIGNAL: calculateVolumeSurge(stock, 49),
                            PRICE_RANGE_SIGNAL: calculatePriceRangeSignal(stock, 49),
                            HL_REVERSAL_SIGNAL: calculateHLReversal(stock)
                        };
                    });

                    console.log("Result:", result);
                    const value = calculateLOM(processedData);
                    setData(value.flat()); // Flatten the original result for `setData`
                    const calculateData = calculateMomentumSpike(result);
                    const formattedData5Minute = result
                        .sort((a, b) => b.PRICECHANGE - a.PRICECHANGE)
                        .slice(2)
                        .map(({ INSTRUMENTIDENTIFIER, PRICECHANGE }) => [
                            INSTRUMENTIDENTIFIER.slice(0, -2),
                            "All Stocks",
                            PRICECHANGE,
                        ]);
                    const formattedData10Minute = calculateData
                        .sort((a, b) => b.MOMENTUM_SCORE - a.MOMENTUM_SCORE)
                        .slice(2)
                        .map(({ INSTRUMENTIDENTIFIER, PRICECHANGE }) => [
                            INSTRUMENTIDENTIFIER.slice(0, -2),
                            "All Stocks",
                            PRICECHANGE,
                        ]);
                    console.log("Formatted 5 minute Data:", formattedData5Minute);
                    console.log("Formatted 10 minute Data:", formattedData10Minute);
                    setMergedData((prevData) => [...prevData, ...formattedData5Minute]);
                    setMergedData10((prevData) => [...prevData, ...formattedData10Minute]);
                    console.log("Processed Result:", processedData);
                } else {
                    console.error("Invalid data format:", result);
                }
            })
            .catch(error => console.error("Error fetching stock data:", error));
    }, [])
    const calculateRSI = (stock, period = 14) => {
        const closePrices = stock.day50.slice(0, period + 1).map(day => Number(day.CLOSE));

        let gains = 0, losses = 0;
        for (let i = 1; i < closePrices.length; i++) {
            let diff = closePrices[i] - closePrices[i - 1];
            if (diff > 0) gains += diff;
            else losses += Math.abs(diff);
        }

        const avgGain = gains / period;
        const avgLoss = losses / period;
        const RS = avgLoss === 0 ? 100 : avgGain / avgLoss;
        const RSI = 100 - (100 / (1 + RS));

        return RSI;
    };

    const getRSISignal = (stock) => {
        const rsi = calculateRSI(stock);

        if (rsi < 30) return "BUY"; // Oversold condition
        if (rsi > 70) return "SELL"; // Overbought condition
        return "HOLD"; // Neutral
    };
    const calculateReversalSignal = (stock) => {
        const lastTradePrice = Number(stock.LASTTRADEPRICE);
        const prevHigh = Number(stock.day50[1].HIGH); // Yesterday's high
        const prevLow = Number(stock.day50[1].LOW);   // Yesterday's low

        if (lastTradePrice > prevHigh) return "BUY";  // Bullish reversal
        if (lastTradePrice < prevLow) return "SELL";  // Bearish reversal
        return "HOLD";  // No reversal
    };
    const calculateVolumeSurge = (stock, period = 50) => {
        const todayVolume = Number(stock.TOTALQTYTRADED);

        // Calculate average volume over last 'N' days
        const avgVolume = stock.day50.slice(0, period)
            .reduce((sum, day) => sum + Number(day.TOTALQTYTRADED), 0) / period;

        if (todayVolume > 2 * avgVolume) return "BUY";  // Strong bullish interest
        if (todayVolume < 0.5 * avgVolume) return "SELL";  // Weak market interest
        return "HOLD";  // Normal volume
    };
    const calculatePriceRangeSignal = (stock, period = 50) => {
        const todayRange = Number(stock.HIGH) - Number(stock.LOW);
        
        // Calculate average price range over last 'N' days
        const avgRange = stock.day50.slice(0, period)
            .reduce((sum, day) => sum + (Number(day.HIGH) - Number(day.LOW)), 0) / period;
        return todayRange < 0.5 * avgRange ? "SELL" :todayRange < 1 * avgRange?"BUY":"HOLD";
    };
    const calculateHLReversal = (stock) => {
        const lastTradePrice = Number(stock.LASTTRADEPRICE);
        const dayHigh = Number(stock.HIGH);
        const dayLow = Number(stock.LOW);
        const priceChange = Number(stock.PRICECHANGE);
    
        if (lastTradePrice < dayHigh && priceChange < 0) return "SELL"; // Bearish Reversal
        if (lastTradePrice > dayLow && priceChange > 0) return "BUY";  // Bullish Reversal
        return "HOLD";  // No strong reversal
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="card mb-0">
                        <div className="card-body px-0">
                            <div className="fs-1 fw-bold text-gradient">Inside Edge</div>
                        </div>
                    </div>
                    <Row>
                        <Col md={12}>
                            <MomentumSpike header={"5 min Flash momentum"} data={mergedData} />
                        </Col>
                        <Col md={12}>
                            <MomentumSpike header={"10 min Flash momentum"} data={mergedData10} />
                        </Col>
                    </Row>
                    {!isEmpty(data) &&
                        <Row>
                            <Col md={6} id="right" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SIGNAL: stock.RSI_SIGNAL
                                })).sort((a, b) => a.CHANGE_PERCENT - b.CHANGE_PERCENT)} type={'highPowerd'} header={"Short term track"} tableId={'pow1'} />
                            </Col>
                            <Col md={6} id="left" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SIGNAL: stock.REVERSAL_SIGNAL
                                })).sort((a, b) => a.CHANGE_PERCENT - b.CHANGE_PERCENT)} type={'highPowerd'} header={"Long Term track "} tableId={'pow2'} />
                            </Col>
                            <Col md={6} id="left1" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SIGNAL: stock.VOLUME_SIGNAL
                                })).sort((a, b) => a.CHANGE_PERCENT - b.CHANGE_PERCENT)} type={'highPowerd'} header={"Contraction Signal"} tableId={'pow3'} />
                            </Col>
                            <Col md={6} id="left3" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SIGNAL: stock.PRICE_RANGE_SIGNAL
                                })).sort((a, b) => a.CHANGE_PERCENT - b.CHANGE_PERCENT)} type={'highPowerd'} header={"Intraday Flip"} tableId={'pow4'} />
                            </Col>
                            <Col md={6} id="left3" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SIGNAL: stock.HL_REVERSAL_SIGNAL
                                })).sort((a, b) => a.CHANGE_PERCENT - b.CHANGE_PERCENT)} type={'highPowerd'} header={"2D Breakout Trigger"} tableId={'pow5'} />
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};

InsiderStrategy.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(InsiderStrategy);
