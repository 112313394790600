import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withTranslation } from "react-i18next"
import _, { isEmpty, result, set } from "lodash"
let bgvector = "./images/vector2.png"
import { shortProductListDataList } from "services/api/api-service"
import { getStrikePrice } from "../../services/api/api-service"
import stockType from "../dataType/stock.json";
import NightingaleChart from "pages/AllCharts/NightingaleChart";
const IndexMover = () => {
    const [typeList] = useState([
        "NIFTY",
        "BANKNIFTY",
        "FINNIFTY",
        "MIDCAP",
        "SENSEX",
    ])
    const [selectedValue, setSelectedValue] = useState("NIFTY");
    const [dataList1, setDataList1] = useState([]);
    const [pts, setPts] = useState(0)
    const [priceChg, setPriceChg] = useState(0);
    const [ganerLoss, setganerLoss] = useState({ ganner: 0, lowser: 0, total: 0,pganner:0,plowser:0 });
    const fetchData = async () => {
        try {
            const resultStrike = await getStrikePrice(selectedValue)
            console.log("resultStrike", resultStrike)
            if (resultStrike?.StrikePrice?.Item) {
                const {
                    CLOSE = 0,
                    OPEN = 0,
                    PRICECHANGEPERCENTAGE = 0,
                } = resultStrike.StrikePrice.Item
                console.log("Price Difference:", Number(CLOSE) - Number(OPEN))
                setPts(Number(CLOSE) - Number(OPEN))
                setPriceChg(Number(PRICECHANGEPERCENTAGE))
            } else {
                console.warn("Missing StrikePrice data:", resultStrike)
            }
        } catch (err) {
            console.error("Error fetching data:", err)
        }
    }
    useEffect(() => {
        let gcount=0;
        let lcount=0;
        shortProductListDataList().then(result => {
            if (!isEmpty(result)) {
                const outputData1 = [ // Header row
                    ...result.sort((a, b) => b.PRICECHANGEPERCENTAGE - a.PRICECHANGEPERCENTAGE).map(item => ({ value: item.PRICECHANGEPERCENTAGE, name: item.INSTRUMENTIDENTIFIER.slice(0, -2) }))
                ];
                const validSymbols = stockType.filter(item => item.category === selectedValue);
                const validSymbols1 = new Set(validSymbols.map(item => item.symbol));
                const filteredStocks = outputData1.filter(stock => validSymbols1.has(stock.name));
                filteredStocks.forEach(item => {
                    if (item.value > 0) {
                        gcount++;
                    } else {
                        lcount++;
                    }
                })
                const total = gcount + lcount;
                const gannerPercentage = total > 0 ? (gcount / total) * 100 : 0;
                const lowserPercentage = total > 0 ? (lcount / total) * 100 : 0;
                console.log(`Gainers: ${gannerPercentage.toFixed(2)}%, Losers: ${lowserPercentage.toFixed(2)}%`);
                setganerLoss({ ganner: gcount, lowser: lcount, total: total,pganner:gannerPercentage,plowser:lowserPercentage });
                setDataList1(filteredStocks);
            }
        })
        fetchData();
    }, [selectedValue]) // Only re-run when selectedValue changes

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="card mb-0">
                        <div className="card-body px-0 pt-0">
                            <div className="row d-flex justify-content-end">
                                <div className="col-9 col-md-9 pb-3">
                                    <div className="fs-1 fw-bold text-white">Index Mover</div>
                                </div>
                                <div className="col-3 col-md-3 d-flex justify-content-end">
                                    <select
                                        value={selectedValue}
                                        onChange={handleChange}
                                        className="form-select form-select-sm"
                                        aria-label="Default select example"
                                    >
                                        {typeList.map(item => (
                                            <option selected value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col md={3}>
                            <Card
                                className="my-2 Drag "
                                style={{
                                    border: "1px solid transparent",
                                    borderRadius: "14px",
                                    boxShadow:
                                        "0 0 0 1px rgba(56, 62, 214, 0.5), 0 0 0 2px rgba(18, 18, 20, 0.5)",
                                    padding: "8px",
                                    backgroundColor: "#181a33",
                                    backgroundImage: `url(${bgvector})`,
                                    backgroundPosition: "bottom -1px right -1px",
                                    backgroundRepeat: "no-repeat",
                                }}
                            >
                                <CardBody className="d-flex justify-content-between rounded-4  ">
                                    <div className="pb-3">
                                        <div className="fs-1 fw-bold text-gradient w-100">
                                            {selectedValue}
                                        </div>
                                        <div className="fs-1 fw-bold text-gradient w-100">
                                            {priceChg}
                                        </div>
                                    </div>
                                    <div className="fs-3 text-white">
                                        UP {pts.toFixed(2)}pts <br />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={9}>
                            <Card
                                className="my-2 Drag "
                                style={{
                                    border: "1px solid transparent",
                                    borderRadius: "14px",
                                    boxShadow:
                                        "0 0 0 1px rgba(56, 62, 214, 0.5), 0 0 0 2px rgba(18, 18, 20, 0.5)",
                                    padding: "10px",
                                    paddingTop: "30px",
                                    paddingBottom: "30px",
                                    backgroundColor: "#181a33",
                                }}
                            >
                                <CardBody
                                    className="d-flex justify-content-between rounded-4  "
                                    style={{ backgroundColor: "#181a33" }}
                                >
                                    <div className="w-100">
                                        <div className="fw-bold mb-2 text-white">
                                            Gainers / Losers
                                        </div>
                                        <div className="progress">
                                            <div
                                                className="progress-bar bg-success"
                                                role="progressbar"
                                                style={{ width: `${ganerLoss.pganner}%` }}
                                                aria-valuenow={ganerLoss.pganner}
                                                aria-valuemin="0"
                                                aria-valuemax={100}
                                            ></div>
                                            <div
                                                className="progress-bar bg-danger"
                                                role="progressbar"
                                                style={{ width: `${ganerLoss.plowser}%` }}
                                                aria-valuenow={ganerLoss.plowser}
                                                aria-valuemin="0"
                                                aria-valuemax={100}
                                            ></div>
                                        </div>
                                        <div className="d-flex justify-content-between mt-2 text-white">
                                            <div>
                                                <i className="bx bxs-circle text-success"></i> Gainers:{" "}
                                                {ganerLoss.ganner || 0}
                                            </div>
                                            <div>
                                                <i className="bx bxs-circle text-danger"></i> Losers:{" "}
                                                {ganerLoss.lowser || 0}
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Card
                        className="my-2 Drag "
                        style={{
                            border: "1px solid transparent",
                            borderRadius: "14px",
                            boxShadow:
                                "0 0 0 1px rgba(56, 62, 214, 0.5), 0 0 0 2px rgba(18, 18, 20, 0.5)",
                            padding: "10px",
                            backgroundColor: "#181a33",
                        }}
                    >
                        <CardBody
                            className="d-flex justify-content-between rounded-4  "
                            style={{ backgroundColor: "#181a33" }}
                        >
                             <NightingaleChart dataList={dataList1.slice(0,10)} title={selectedValue} />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            {/* <FyersIntegration /> */}
        </React.Fragment>
    )
}
IndexMover.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
}

export default withTranslation()(IndexMover)
