import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Row, Col } from "reactstrap";
import Badge from 'react-bootstrap/Badge';

const NightingaleChart = ({ dataList = [], title }) => {  
  const chartRef = useRef(null);

  useEffect(() => {
    let myChart = null;
    if (chartRef.current) {
      myChart = echarts.init(chartRef.current);

      const option = {
        backgroundColor: 'transparent', // ✅ Transparent for better UI
        toolbox: { show: false },
        textStyle: { color: '#fff' }, // ✅ White text
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: [80, 150],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 15,
              margin: 10,
              color: (params) => (params.data.value >= 2 ? '#0f0' : '#f00'),
            },
            label: {
              color: '#fff', // ✅ White labels
              fontSize: 14,
            },
            data: dataList,
          },
        ],
      };
      myChart.setOption(option);
    }
    return () => myChart?.dispose();
  }, [dataList]);

  return (
    <Row style={{ width: '100%' }}>
      <Col md={8}>
        <div ref={chartRef} style={{ width: '100%', height: '500px' }} />
      </Col>
      <Col md={4}>
        <h4 className='fs-1 fw-bold text-gradient'>{title}</h4>
        <ul className="list-group" style={{backgroundColor:'#000000'}}>
          {dataList.length > 0 ? (
            dataList.map((item, index) => (
              <li key={index} className="list-group-item">
                {item.name} added <Badge bg={item.value > 1 ? 'success' : 'danger'}>{item.value}</Badge> pts
              </li>
            ))
          ) : (
            <p style={{ color: 'white' }}>No data available</p>
          )}
        </ul>
      </Col>
    </Row>
  );
};

export default NightingaleChart;
