import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import axios from 'axios';
import { withTranslation } from "react-i18next";
import _, { isEmpty } from "lodash";
import TableCard from "pages/Marketpulse/TableCard";
import CardSlider from "./CardSlider";
import { TdStockDataHistory } from "services/api/api-service"
import moment from "moment";
const MarketPulse = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        TdStockDataHistory().then(result => {
            console.log("Result:", result);
            if (result && typeof result === 'object') {
                // Filter out invalid stocks
                const processedData = result
                    .filter(stock => stock.day50 && stock.day50.length >= 6) // Ensure valid data
                    .map(stock => {
                        const avgHigh6 = (Number(stock.day50[0].HIGH) + Number(stock.day50[1].HIGH) + Number(stock.day50[2].HIGH) +
                            Number(stock.day50[3].HIGH) + Number(stock.day50[4].HIGH) + Number(stock.day50[5].HIGH)) / 6;
        
                        const lastTradePrice = Number(stock.LASTTRADEPRICE);
                        const avgTradePrice = Number(stock.AVERAGETRADEDPRICE);
                        const changePercent = Number(stock.PRICECHANGEPERCENTAGE);
        
                        // Define AI-based Signal
                        let AI_SIGNAL = "HOLD"; // Default
                        if (lastTradePrice > avgHigh6 && changePercent > 1) {
                            AI_SIGNAL = "BUY";
                        } else if (lastTradePrice < avgTradePrice && changePercent < -1) {
                            AI_SIGNAL = "SELL";
                        }
        
                        const bear_SIGNAL = ((stock.BUYPRICE - stock.LOW) / stock.LOW).toFixed(2);
        
                        // Determine buy, sell, and hold stages
                        let stage = "HOLD"; // Default
                        if (AI_SIGNAL === "BUY" && bear_SIGNAL > 1.5) {
                            stage = "BUY";
                        } else if (AI_SIGNAL === "SELL" && bear_SIGNAL > 1.5) {
                            stage = "SELL";
                        }
        
                        const momentAGValue_SIGNAL = (stock.AVERAGETRADEDPRICE * stock.LASTTRADEQTY).toFixed(2);
                        const turboTrades_SIGNAL = (((stock.AVERAGETRADEDPRICE * stock.LASTTRADEQTY) / (stock.TOTALQTYTRADED * stock.AVERAGETRADEDPRICE)) * 100).toFixed(2);
                        const bullForce_SIGNAL = ((stock.HIGH - stock.LOW) / stock.BUYPRICE).toFixed(2);
        
                        return {
                            ...stock,
                            AI_SIGNAL,
                            CHANGE_PERCENT: changePercent,
                            momentAG: momentAGValue_SIGNAL,
                            turboTrades: turboTrades_SIGNAL,
                            bullForce: bullForce_SIGNAL,
                            bearForce: bear_SIGNAL,
                            wealthWinners: stock.PRICECHANGEPERCENTAGE,
                        };
                    });
        
                setData(processedData);
                console.log("Processed Result:", processedData);
            } else {
                console.error("Invalid data format:", result);
            }
        }).catch(error => console.error("Error fetching stock data:", error));
        
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="card mb-0">
                        <div className="card-body p-0">
                            <div className="tradingview-widget-container h-0" id="tradingview-widget">
                                <div className="tradingview-widget-container__widget"></div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-0">
                        <div className="card-body px-0">
                            <div className="fs-1 fw-bold text-gradient">Trade Radar</div>
                        </div>
                    </div>
                    {!isEmpty(data) &&
                        <Row>
                            <Col md={12} className="hideOnDesktop mb-3">
                                <CardSlider list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.momentAG,
                                })).sort((a, b) => b.momentAG - a.momentAG)} type={'highPowerd'} header={"Momentum Movers"} />
                            </Col>
                            <Col md={12} className="hideOnDesktop mb-3">
                                <CardSlider list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.turboTrades,
                                })).sort((a, b) => a.turboTrades - b.turboTrades)} type={'highPowerd'} header={"Turbo Trades"} />
                            </Col>
                            <Col md={12} className="hideOnDesktop mb-3">
                                <CardSlider list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.bullForce,
                                })).sort((a, b) => a.bullForce - b.bullForce)} type={'highPowerd'} header={"Bull Force"} />
                            </Col>
                            <Col md={12} className="hideOnDesktop mb-3">
                                <CardSlider list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.bearForce,
                                })).sort((a, b) => a.bearForce - b.bearForce)} type={'highPowerd'} header={"Bear Force"} />
                            </Col>
                            <Col md={12} className="hideOnDesktop mb-3">
                                <CardSlider list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.wealthWinners,
                                })).sort((a, b) => b.wealthWinners - a.wealthWinners)} type={'highPowerd'} header={"wealth Winners"} />
                            </Col>
                            <Col md={12} className="hideOnDesktop mb-3">
                                <CardSlider list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.wealthWinners,
                                })).sort((a, b) => a.wealthWinners - b.wealthWinners)} type={'highPowerd'} header={"wealth Loosers"} />
                            </Col>
                        </Row>
                    }
                    {!isEmpty(data) &&
                        <Row>
                            <Col md={6} id="right" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.momentAG,
                                })).sort((a, b) => b.momentAG - a.momentAG)} type={'highPowerd'} header={"Momentum Movers"} tableId={'pow1'} />
                            </Col>
                            <Col md={6} id="left3" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.turboTrades,
                                })).sort((a, b) => a.turboTrades - b.turboTrades)} type={'highPowerd'} header={"Turbo Trades"} tableId={'pow4'} />
                            </Col>
                            <Col md={6} id="left" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.bullForce,
                                })).sort((a, b) => a.bullForce - b.bullForce)} type={'highPowerd'} header={"Bull Force"} tableId={'pow2'} />
                            </Col>
                            <Col md={6} id="left1" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.bearForce,
                                })).sort((a, b) => a.bearForce - b.bearForce)} type={'highPowerd'} header={"Bear Force"} tableId={'pow3'} />
                            </Col>
                            <Col md={6} id="left3" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.wealthWinners,
                                })).sort((a, b) => b.wealthWinners - a.wealthWinners)} type={'highPowerd'} header={"wealth Winners"} tableId={'pow5'} />
                            </Col>
                            <Col md={6} id="left3" className="hideOnMobile">
                                <TableCard list={data.map(stock => ({
                                    ...stock,
                                    SINGNAL: stock.wealthWinners,
                                })).sort((a, b) => a.wealthWinners - b.wealthWinners)} type={'highPowerd'} header={"wealth Loosers"} tableId={'pow5'} />
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};
MarketPulse.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(MarketPulse);
