import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { withTranslation } from "react-i18next";
import {
    shortProductListDataList,
} from "services/api/api-service";
import _, { isEmpty } from "lodash";
import { getHistoryList } from "services/api/api-service";
import MomentumSpike from "../InsiderStrategy/MomentumSpike";
import NightingaleChart from "pages/AllCharts/NightingaleChart";
import stockType from "../dataType/stock.json";
import CandlestickChart from "pages/AllCharts/chart-bar";
const OptionApex = (props) => {
    let [mergedData, setMergedData] = useState([
        ["Symbol", "Parent", "Price Change"],
        ["All Stocks", null, 0],
    ]);
    const [typeList] = useState([
        "NIFTY",
        "BANKNIFTY",
        "FINNIFTY",
        "MIDCAP",
        "SENSEX",
    ])
    const [selectedValue, setSelectedValue] = useState("NIFTY");
    const [dataList1, setDataList1] = useState([]);
    const [dataList2, setDataList2] = useState([]);
    useEffect(() => {
        document.title = "Option Apex | Trendsarthi";
        shortProductListDataList().then(result => {
            if (!isEmpty(result)) {
                const outputData1 = [ // Header row
                    ...result.sort((a, b) => b.PRICECHANGEPERCENTAGE - a.PRICECHANGEPERCENTAGE).map(item => ({ value: item.PRICECHANGEPERCENTAGE, name: item.INSTRUMENTIDENTIFIER.slice(0, -2) }))
                ];
                const validSymbols = stockType.filter(item => item.category === selectedValue);
                const validSymbols1 = new Set(validSymbols.map(item => item.symbol));
                const filteredStocks = outputData1.filter(stock => validSymbols1.has(stock.name));
                setDataList1(filteredStocks);
                const formattedData = filteredStocks
                    .sort((a, b) => b.PRICECHANGE - a.PRICECHANGE)
                    .slice(0, 10)
                    .map(({ name, value }) => [
                        name,
                        "All Stocks",
                        value,
                    ]);
                setMergedData((prevData) => [...prevData, ...formattedData]);
            }
        })
        getHistoryData(selectedValue);
    }, [selectedValue]) // Only re-run when selectedValue changes
    const getHistoryData = (type) => {
        getHistoryList('MINUTE', type, 200, 1).then(result => {
            if (!isEmpty(result)) {
                const outputData1 = [ // Header row
                    ...result.map(item => ({ x: new Date(item.LASTTRADETIME), y: [item.OPEN, item.HIGH, item.LOW, item.CLOSE] }))
                ];
                setDataList2(outputData1);
            }
        })
    }
    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="card mb-0">
                        <div className="card-body px-0 pt-0">
                            <div className="row d-flex justify-content-between">
                                <div className="col-md-9 pb-3">
                                    <div className="fs-1 fw-bold text-gradient">Option Apex</div>
                                </div>
                                <div className="col-md-3 d-flex justify-content-around">
                                    <select
                                        value={selectedValue}
                                        onChange={handleChange}
                                        className="form-select form-select-sm"
                                        aria-label="Default select example"
                                    >
                                        {typeList.map(item => (
                                            <option selected value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col md={6}>
                            <Card
                                className="my-2"
                                style={{
                                    border: '1px solid transparent',
                                    borderRadius: '14px',
                                    boxShadow: '0 0 0 1px rgba(56, 62, 214, 0.5), 0 0 0 2px rgba(18, 18, 20, 0.5)',
                                    padding: '10px',
                                    backgroundColor: "#181a33"
                                }}
                            >
                                <CardBody className='justify-content-between rounded-4' style={{ backgroundColor: "#181a33" }}>
                                    <CandlestickChart datalist={dataList2} type={selectedValue} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <MomentumSpike header={"Money Flux"} data={mergedData} />
                        </Col>
                    </Row>
                    <Card
                        className="my-2 Drag"
                        style={{
                            border: '1px solid transparent',
                            borderRadius: '14px',
                            boxShadow: '0 0 0 1px rgba(56, 62, 214, 0.5), 0 0 0 2px rgba(18, 18, 20, 0.5)',
                            padding: '10px',
                            backgroundColor: "#181a33"
                        }}
                    >
                        <CardBody className='d-flex justify-content-between rounded-4' style={{ backgroundColor: "#181a33" }}>
                            <NightingaleChart dataList={dataList1.slice(0, 10)} title={selectedValue} />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};
OptionApex.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(OptionApex);
