import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const CandlestickChart = ({ datalist = [], type = 'Candlestick' }) => {
  const [chartData, setChartData] = useState(datalist);

  useEffect(() => {
    setChartData(datalist); // ✅ Update chart data when datalist changes
  }, [datalist]);

  const options = {
    chart: {
      type: 'candlestick',
      height: 450,
      background: 'transparent', // ✅ Transparent background for better styling
    },
    title: {
      text: `${type} Chart`,
      align: 'left',
      style: { color: '#fff' }, // ✅ White title for better visibility
    },
    xaxis: {
      type: 'datetime',
      labels: { style: { colors: '#fff' } }, // ✅ White labels
    },
    yaxis: {
      tooltip: { enabled: true },
      labels: { style: { colors: '#fff' } }, // ✅ White labels
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: '#00ff00', // ✅ Green for positive
          downward: '#ff0000', // ✅ Red for negative
        },
        wick: { useFillColor: true },
      },
    },
  };

  return (
    <div>
      <ReactApexChart options={options} series={[{ data: chartData }]} type="candlestick" height={450} />
    </div>
  );
};

export default CandlestickChart;
