export const sector=[
    { "symbol": "AARTIIND", "sector": "Pharmaceuticals" },
    { "symbol": "ABB", "sector": "Engineering" },
    { "symbol": "ABBOTINDIA", "sector": "Pharmaceuticals" },
    { "symbol": "ABCAPITAL", "sector": "Financial Services" },
    { "symbol": "ABFRL", "sector": "Textiles" },
    { "symbol": "ACC", "sector": "Construction & Infrastructure" },
    { "symbol": "ADANIENSOL", "sector": "Energy" },
    { "symbol": "ADANIENT", "sector": "Construction & Infrastructure" },
    { "symbol": "ADANIGREEN", "sector": "Energy" },
    { "symbol": "ADANIPORTS", "sector": "Energy" },
    { "symbol": "ALKEM", "sector": "Pharmaceuticals" },
    { "symbol": "AMBUJACEM", "sector": "Construction & Infrastructure" },
    { "symbol": "ANGELONE", "sector": "Financial Services" },
    { "symbol": "APLAPOLLO", "sector": "Construction & Infrastructure" },
    { "symbol": "APOLLOHOSP", "sector": "Healthcare" },
    { "symbol": "APOLLOTYRE", "sector": "Automotive" },
    { "symbol": "ASHOKLEY", "sector": "Automotive" },
    { "symbol": "ASIANPAINT", "sector": "Consumer Goods" },
    { "symbol": "ASTRAL", "sector": "Consumer Discretionary" },
    { "symbol": "ATGL", "sector": "Energy" },
    { "symbol": "ATUL", "sector": "Other" },
    { "symbol": "AUBANK", "sector": "Financial Services" },
    { "symbol": "AUROPHARMA", "sector": "Pharmaceuticals" },
    { "symbol": "AXISBANK", "sector": "Financial Services" },
    { "symbol": "BAJAJ-AUTO", "sector": "Automotive" },
    { "symbol": "BAJAJFINSV", "sector": "Financial Services" },
    { "symbol": "BAJFINANCE", "sector": "Financial Services" },
    { "symbol": "BALKRISIND", "sector": "Automotive" },
    { "symbol": "BANDHANBNK", "sector": "Financial Services" },
    { "symbol": "BANKBARODA", "sector": "Financial Services" },
    { "symbol": "BANKINDIA", "sector": "Financial Services" },
    { "symbol": "BANKNIFTY", "sector": "Financial Services" },
    { "symbol": "BATAINDIA", "sector": "Consumer Goods" },
    { "symbol": "BEL", "sector": "Engineering" },
    { "symbol": "BERGEPAINT", "sector": "Consumer Goods" },
    { "symbol": "BHARATFORG", "sector": "Engineering" },
    { "symbol": "BHARTIARTL", "sector": "Telecommunications" },
    { "symbol": "BHEL", "sector": "Engineering" },
    { "symbol": "BIOCON", "sector": "Pharmaceuticals" },
    { "symbol": "BOSCHLTD", "sector": "Engineering" },
    { "symbol": "BPCL", "sector": "Energy" },
    { "symbol": "BRITANNIA", "sector": "Consumer Goods" },
    { "symbol": "BSE", "sector": "Financial Services" }, // Added sector
    { "symbol": "BSOFT", "sector": "Information Technology" },
    { "symbol": "CAMS", "sector": "Technology" }, // Corrected sector
    { "symbol": "CANBK", "sector": "Financial Services" }, // Added sector
    { "symbol": "CANFINHOME", "sector": "Financial Services" },
    { "symbol": "CDSL", "sector": "Technology" },
    { "symbol": "CESC", "sector": "Other" },
    { "symbol": "CGPOWER", "sector": "Engineering" },
    { "symbol": "CHAMBLFERT", "sector": "Chemicals & Fertilizers" },
    { "symbol": "CHOLAFIN", "sector": "Financial Services" },
    { "symbol": "CIPLA", "sector": "Pharmaceuticals" },
    { "symbol": "COALINDIA", "sector": "Energy" }, // Added sector
    { "symbol": "COFORGE", "sector": "Information Technology" },
    { "symbol": "COLPAL", "sector": "Consumer Goods" },
    { "symbol": "CONCOR", "sector": "Logistics" },
    { "symbol": "COROMANDEL", "sector": "Chemicals & Fertilizers" },
    { "symbol": "CROMPTON", "sector": "Consumer Goods" },
    { "symbol": "CUB", "sector": "Financial Services" },
    { "symbol": "CUMMINSIND", "sector": "Engineering" },
    { "symbol": "CYIENT", "sector": "Information Technology" },
    { "symbol": "DABUR", "sector": "Consumer Goods" },
    { "symbol": "DALBHARAT", "sector": "Construction & Infrastructure" },
    { "symbol": "DEEPAKNTR", "sector": "Chemicals & Fertilizers" },
    { "symbol": "DELHIVERY", "sector": "Logistics" },
    { "symbol": "DIVISLAB", "sector": "Pharmaceuticals" },
    { "symbol": "DIXON", "sector": "Engineering" },
    { "symbol": "DLF", "sector": "Construction & Infrastructure" },
    { "symbol": "DMART", "sector": "Consumer Goods" },
    { "symbol": "DRREDDY", "sector": "Pharmaceuticals" },
    { "symbol": "EICHERMOT", "sector": "Automotive" },
    { "symbol": "ESCORTS", "sector": "Automotive" },
    { "symbol": "EXIDEIND", "sector": "Automotive" }, // Added sector
    { "symbol": "FEDERALBNK", "sector": "Financial Services" },
    { "symbol": "FINNIFTY", "sector": "Financial Services" },
    { "symbol": "GAIL", "sector": "Energy" },
    { "symbol": "GLENMARK", "sector": "Pharmaceuticals" },
    { "symbol": "GMRAIRPORT", "sector": "Infrastructure" }, // Added sector
    { "symbol": "GMRINFRA", "sector": "Infrastructure" }, // Added sector
    { "symbol": "GNFC", "sector": "Energy" },
    { "symbol": "GODREJCP", "sector": "Consumer Goods" },
    { "symbol": "GODREJPROP", "sector": "Construction & Infrastructure" },
    { "symbol": "GRANULES", "sector": "Pharmaceuticals" },
    { "symbol": "GRASIM", "sector": "Construction & Infrastructure" },
    { "symbol": "GUJGASLTD", "sector": "Energy" }, // Added Sector
    { "symbol": "HAL", "sector": "Engineering" },
    { "symbol": "HAVELLS", "sector": "Engineering" },
    { "symbol": "HCLTECH", "sector": "Information Technology" },
    { "symbol": "HDFCAMC", "sector": "Financial Services" },
    { "symbol": "HDFCBANK", "sector": "Financial Services" },
    { "symbol": "HDFCLIFE", "sector": "Financial Services" },
    { "symbol": "HEROMOTOCO", "sector": "Automotive" }, // Added sector
    { "symbol": "HFCL", "sector": "Other" },
    { "symbol": "HINDALCO", "sector": "Metals & Mining" },
    { "symbol": "HINDCOPPER", "sector": "Metals & Mining" },
    { "symbol": "HINDPETRO", "sector": "Energy" },
    { "symbol": "HINDUNILVR", "sector": "Consumer Goods" },
    { "symbol": "HUDCO", "sector": "Construction & Infrastructure" },
    { "symbol": "ICICIBANK", "sector": "Financial Services" },
    { "symbol": "ICICIGI", "sector": "Financial Services" },
    { "symbol": "ICICIPRULI", "sector": "Financial Services" },
    { "symbol": "IDEA", "sector": "Telecommunications" },
    { "symbol": "IDFCFIRSTB", "sector": "Financial Services" },
    { "symbol": "IEX", "sector": "Technology" },
    { "symbol": "IGL", "sector": "Energy" }, // Added sector
    { "symbol": "INDHOTEL", "sector": "Hotels & Tourism" },
    { "symbol": "INDIAMART", "sector": "Technology" },
    { "symbol": "INDIANB", "sector": "Financial Services" },
    { "symbol": "INDIGO", "sector": "Airlines" },
    { "symbol": "INDUSINDBK", "sector": "Financial Services" },
    { "symbol": "INDUSTOWER", "sector": "Telecommunications" },
    { "symbol": "INFY", "sector": "Information Technology" },
    { "symbol": "IOC", "sector": "Energy" },
    { "symbol": "IPCALAB", "sector": "Pharmaceuticals" },
    { "symbol": "IRB", "sector": "Other" },
    { "symbol": "IRCTC", "sector": "Other" },
    { "symbol": "IRFC", "sector": "Other" },
    { "symbol": "ITC", "sector": "Consumer Goods" },
    { "symbol": "JINDALSTEL", "sector": "Metals & Mining" },
    { "symbol": "JIOFIN", "sector": "Financial Services" },
    { "symbol": "JKCEMENT", "sector": "Construction & Infrastructure" },
    { "symbol": "JSL", "sector": "Metals & Mining" },
    { "symbol": "JSWENERGY", "sector": "Energy" },
    { "symbol": "JSWSTEEL", "sector": "Construction & Infrastructure" },
    { "symbol": "JUBLFOOD", "sector": "Consumer Goods" },
    { "symbol": "KALYANKJIL", "sector": "Other" },
    { "symbol": "KEI", "sector": "Engineering" },
    { "symbol": "KOTAKBANK", "sector": "Financial Services" },
    { "symbol": "KPITTECH", "sector": "Information Technology" },
    { "symbol": "LALPATHLAB", "sector": "Healthcare" },
    { "symbol": "LAURUSLABS", "sector": "Pharmaceuticals" },
    { "symbol": "LICHSGFIN", "sector": "Financial Services" },
    { "symbol": "LICI", "sector": "Financial Services" },
    { "symbol": "LODHA", "sector": "Construction & Infrastructure" },
    { "symbol": "LT", "sector": "Construction & Infrastructure" },
    { "symbol": "LTF", "sector": "Financial Services" },
    { "symbol": "LTIM", "sector": "Information Technology" },
    { "symbol": "LTTS", "sector": "Information Technology" },
    { "symbol": "LUPIN", "sector": "Pharmaceuticals" },
    { "symbol": "MFSL", "sector": "Financial Services" },
    { "symbol": "MGL", "sector": "Other" },
    { "symbol": "MIDCPNIFTY", "sector": "Indices" },
    { "symbol": "MOTHERSON", "sector": "Other" },
    { "symbol": "MPHASIS", "sector": "Information Technology" },
    { "symbol": "MRF", "sector": "Other" },
    { "symbol": "MUTHOOTFIN", "sector": "Financial Services" },
    { "symbol": "NATIONALUM", "sector": "Metals & Mining" },
    { "symbol": "NAUKRI", "sector": "Technology" },
    { "symbol": "NAVINFLUOR", "sector": "Pharmaceuticals" },
    { "symbol": "NBCC", "sector": "Construction & Infrastructure" },
    { "symbol": "NCC", "sector": "Construction & Infrastructure" },
    { "symbol": "NESTLEIND", "sector": "Consumer Goods" },
    { "symbol": "NHPC", "sector": "Energy" }, // Added sector
    { "symbol": "NIFTY", "sector": "Financial Services" },
    { "symbol": "NIFTYNXT50", "sector": "Financial Services" },
    { "symbol": "NMDC", "sector": "Metals & Mining" },
    { "symbol": "NTPC", "sector": "Energy" },
    { "symbol": "NYKAA", "sector": "Technology" },
    { "symbol": "OBEROIRLTY", "sector": "Hotels & Tourism" },
    { "symbol": "OFSS", "sector": "Information Technology" },
    { "symbol": "OIL", "sector": "Energy" },
    { "symbol": "ONGC", "sector": "Energy" },
    { "symbol": "PAGEIND", "sector": "Consumer Goods" },
    { "symbol": "PAYTM", "sector": "Technology" },
    { "symbol": "PEL", "sector": "Pharmaceuticals" },
    { "symbol": "PERSISTENT", "sector": "Information Technology" },
    { "symbol": "PETRONET", "sector": "Energy" },
    { "symbol": "PFC", "sector": "Financial Services" },
    { "symbol": "PHOENIXLTD", "sector": "Other" },
    { "symbol": "PIDILITIND", "sector": "Pharmaceuticals" },
    { "symbol": "PIIND", "sector": "Pharmaceuticals" },
    { "symbol": "PNB", "sector": "Financial Services" },
    { "symbol": "POLICYBZR", "sector": "Technology" },
    { "symbol": "POLYCAB", "sector": "Consumer Goods" },
    { "symbol": "POONAWALLA", "sector": "Financial Services" },
    { "symbol": "POWERGRID", "sector": "Energy" },
    { "symbol": "PRESTIGE", "sector": "Construction & Infrastructure" },
    { "symbol": "PVRINOX", "sector": "Media & Entertainment" },
    { "symbol": "RAMCOCEM", "sector": "Construction & Infrastructure" },
    { "symbol": "RBLBANK", "sector": "Financial Services" },
    { "symbol": "RECLTD", "sector": "Financial Services" },
    { "symbol": "RELIANCE", "sector": "Conglomerates" }, // Added sector
    { "symbol": "SAIL", "sector": "Metals & Mining" },
    { "symbol": "SBICARD", "sector": "Financial Services" },
    { "symbol": "SBILIFE", "sector": "Financial Services" },
    { "symbol": "SBIN", "sector": "Financial Services" },
    { "symbol": "SHREECEM", "sector": "Construction & Infrastructure" },
    { "symbol": "SHRIRAMFIN", "sector": "Financial Services" },
    { "symbol": "SIEMENS", "sector": "Engineering" },
    { "symbol": "SJVN", "sector": "Energy" }, // Added sector
    { "symbol": "SOLARINDS", "sector": "Other" },
    { "symbol": "SONACOMS", "sector": "Automotive" },
    { "symbol": "SRF", "sector": "Chemicals & Fertilizers" },
    { "symbol": "SUNPHARMA", "sector": "Pharmaceuticals" },
    { "symbol": "SUNTV", "sector": "Media & Entertainment" },
    { "symbol": "SUPREMEIND", "sector": "Consumer Goods" },
    { "symbol": "SYNGENE", "sector": "Other" },
    { "symbol": "TATACHEM", "sector": "Consumer Goods" },
    { "symbol": "TATACOMM", "sector": "Telecommunications" },
    { "symbol": "TATAELXSI", "sector": "Technology" }, // Added sector
    { "symbol": "TATAMOTORS", "sector": "Automotive" },
    { "symbol": "TATAPOWER", "sector": "Energy" },
    { "symbol": "TATASTEEL", "sector": "Metals & Mining" },
    { "symbol": "TCS", "sector": "Information Technology" },
    { "symbol": "TECHM", "sector": "Information Technology" },
    { "symbol": "TIINDIA", "sector": "Automotive" },
    { "symbol": "TITAN", "sector": "Consumer Goods" },
    { "symbol": "TORNTPHARM", "sector": "Pharmaceuticals" },
    { "symbol": "TORNTPOWER", "sector": "Energy" },
    { "symbol": "TRENT", "sector": "Consumer Goods" },
    { "symbol": "TVSMOTOR", "sector": "Automotive" },
    { "symbol": "UBL", "sector": "Consumer Goods" },
    { "symbol": "ULTRACEMCO", "sector": "Construction & Infrastructure" },
    { "symbol": "UNIONBANK", "sector": "Financial Services" }, // Added sector
    { "symbol": "UNITDSPR", "sector": "Other" },
    { "symbol": "UPL", "sector": "Chemicals & Fertilizers" },
    { "symbol": "VBL", "sector": "Consumer Goods" },
    { "symbol": "VEDL", "sector": "Metals & Mining" },
    { "symbol": "VOLTAS", "sector": "Engineering" },
    { "symbol": "WIPRO", "sector": "Information Technology" },
    { "symbol": "YESBANK", "sector": "Financial Services" },
    { "symbol": "ZOMATO", "sector": "Technology" },
    { "symbol": "ZYDUSLIFE", "sector": "Pharmaceuticals" }
]