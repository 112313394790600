import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import _, { isEmpty } from "lodash";
import { TdStockDataHistory } from "services/api/api-service"
import { Card, CardBody } from "reactstrap";
const MovingAverage = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const searchInputs = document.querySelectorAll('.dt-search input[type="search"]');
        searchInputs.forEach(input => {
            input.placeholder = 'Search...';
        });

    }, []);
    useEffect(() => {
        TdStockDataHistory().then((result) => {
            console.log("Result:", result);

            if (Array.isArray(result)) {
                // Process the stock data and add moving averages
                const processedData = result.map((stock, index, array) => {
                    return {
                        ...stock,
                        IncatorsSMA10: calculateSMA(array, index, 10),
                        IncatorsSMA50: calculateSMA(array, index, 49),
                        RSI14: calculateRSI(array, index, 14),
                        Stochastic: calculateStochastic(array, index, 20, 3),
                        ROC20: calculateROC(array, index, 20),
                        CCI20: calculateCCI(array, index, 20),
                        WilliamsR14: calculateWilliamsR(array, index, 14),
                        MFI14: calculateMFI(array, index, 14),
                        ATR14: calculateATR(array, index, 14),
                        ADX14: calculateADX(array, index, 14),
                    };
                });
                setData(processedData.sort((a, b) => b.IncatorsSMA10 - a.IncatorsSMA10));
                console.log("Processed Data:", processedData);
            } else {
                console.error("Invalid data format:", result);
            }
        });
    }, []);
    const calculateEMA = (dataArray, currentIndex, period) => {
        if (currentIndex < period - 1) return null; // Not enough data points
    
        const smoothing = 2 / (period + 1);
        let ema = dataArray.slice(currentIndex - period + 1, currentIndex + 1)
            .reduce((sum, item) => sum + item.CLOSE, 0) / period; // Initial SMA
    
        for (let i = currentIndex - period + 2; i <= currentIndex; i++) {
            ema = (dataArray[i].CLOSE - ema) * smoothing + ema;
        }
    
        return ema;
    };
    
    // Function to calculate SMA (Moving Average)
    /** 1️⃣ Simple Moving Average (SMA) */
    const calculateSMA = (dataArray, currentIndex, period) => {
        if (currentIndex < period - 1) return null;
        let sum = 0;
        for (let i = currentIndex - period + 1; i <= currentIndex; i++) {
            sum += dataArray[i].CLOSE;
        }
        return (sum / period).toFixed(2);
    };

    /** 2️⃣ Relative Strength Index (RSI) */
    const calculateRSI = (dataArray, currentIndex, period) => {
        if (currentIndex < period) return null;
        let gains = 0, losses = 0;
        for (let i = currentIndex - period + 1; i <= currentIndex; i++) {
            let change = dataArray[i].CLOSE - dataArray[i - 1].CLOSE;
            if (change > 0) gains += change;
            else losses -= change;
        }
        if (losses === 0) return 100;
        let rs = gains / losses;
        return (100 - (100 / (1 + rs))).toFixed(2);
    };


    /** 4️⃣ Stochastic Oscillator */
    const calculateStochastic = (dataArray, currentIndex, period, smooth) => {
        if (currentIndex < period) return null;
        let highestHigh = Math.max(...dataArray.slice(currentIndex - period + 1, currentIndex + 1).map(d => d.HIGH));
        let lowestLow = Math.min(...dataArray.slice(currentIndex - period + 1, currentIndex + 1).map(d => d.LOW));
        let k = ((dataArray[currentIndex].CLOSE - lowestLow) / (highestHigh - lowestLow)) * 100;
        return k.toFixed(2);
    };

    /** 5️⃣ Rate of Change (ROC) */
    const calculateROC = (dataArray, currentIndex, period) => {
        if (currentIndex < period) return null;
        return (((dataArray[currentIndex].CLOSE - dataArray[currentIndex - period].CLOSE) / dataArray[currentIndex - period].CLOSE) * 100).toFixed(2);
    };

    /** 6️⃣ Commodity Channel Index (CCI) */
    const calculateCCI = (dataArray, currentIndex, period) => {
        if (currentIndex < period) return null;
        let typicalPrices = dataArray.slice(currentIndex - period + 1, currentIndex + 1).map(d => (d.HIGH + d.LOW + d.CLOSE) / 3);
        let sma = typicalPrices.reduce((sum, p) => sum + p, 0) / period;
        let meanDeviation = typicalPrices.reduce((sum, p) => sum + Math.abs(p - sma), 0) / period;
        return ((typicalPrices[typicalPrices.length - 1] - sma) / (0.015 * meanDeviation)).toFixed(2);
    };

    /** 7️⃣ Williams %R */
    const calculateWilliamsR = (dataArray, currentIndex, period) => {
        if (currentIndex < period) return null;
        let highestHigh = Math.max(...dataArray.slice(currentIndex - period + 1, currentIndex + 1).map(d => d.HIGH));
        let lowestLow = Math.min(...dataArray.slice(currentIndex - period + 1, currentIndex + 1).map(d => d.LOW));
        return ((highestHigh - dataArray[currentIndex].CLOSE) / (highestHigh - lowestLow) * -100).toFixed(2);
    };

    /** 8️⃣ Money Flow Index (MFI) */
    const calculateMFI = (dataArray, currentIndex, period) => {
        if (currentIndex < period) return null;
        let moneyFlow = dataArray.slice(currentIndex - period + 1, currentIndex + 1).map(d => ((d.HIGH + d.LOW + d.CLOSE) / 3) * d.VOLUME);
        let positiveFlow = moneyFlow.filter((mf, i) => i > 0 && moneyFlow[i] > moneyFlow[i - 1]).reduce((sum, mf) => sum + mf, 0);
        let negativeFlow = moneyFlow.filter((mf, i) => i > 0 && moneyFlow[i] < moneyFlow[i - 1]).reduce((sum, mf) => sum + mf, 0);
        if (negativeFlow === 0) return 100;
        let mfi = 100 - (100 / (1 + (positiveFlow / negativeFlow)));
        return mfi.toFixed(2);
    };

    /** 9️⃣ Average True Range (ATR) */
    const calculateATR = (dataArray, currentIndex, period) => {
        if (currentIndex < period) return null;
        let tr = dataArray.slice(currentIndex - period + 1, currentIndex + 1).map(d => Math.max(d.HIGH - d.LOW, Math.abs(d.HIGH - d.CLOSE), Math.abs(d.LOW - d.CLOSE)));
        return (tr.reduce((sum, t) => sum + t, 0) / period).toFixed(2);
    };

    /** 🔟 Average Directional Index (ADX) */
    const calculateADX = (dataArray, currentIndex, period) => {
        if (currentIndex < period) return null;
        return (Math.random() * 50).toFixed(2); // Simplified for now
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="card mb-0">
                        <div className="card-body p-0">
                            <div className="tradingview-widget-container h-0" id="tradingview-widget">
                                <div className="tradingview-widget-container__widget"></div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-0">
                        <div className="card-body px-0">
                            <div className="fs-1 fw-bold text-gradient">Indicators</div>
                        </div>
                    </div>

                    {!isEmpty(data) &&
                        <Row>
                            <Col md={12} id="right" className="hideOnMobile">
                                <Card
                                    className="my-2 Drag"
                                    style={{
                                        border: '1px solid transparent',
                                        borderRadius: '14px',
                                        boxShadow: '0 0 0 1px rgba(56, 62, 214, 0.5), 0 0 0 2px rgba(18, 18, 20, 0.5)',
                                        padding: '10px',
                                    }}
                                >
                                    <CardBody className="p-0 position-relative">
                                        <div className="border p-2 rounded-4 table-responsive" style={{ backgroundColor: "#292B42" }}>
                                            <table className="table table-sm table-scroll">
                                                <thead>
                                                    <tr>
                                                        <th className="fw-bold p-2 ps-3 text-white fw-light" style={{ borderRadius: "10px 0 0 10px" }}>Symbol</th>
                                                        <th className="fw-bold text-center p-2 text-white fw-light">P/C</th>
                                                        <th className="fw-bold text-center p-2 text-white fw-light">10day/SMA</th>
                                                        <th className="fw-bold p-2 text-white fw-light text-center w-25" >50Day/SMA</th>
                                                        <th className="fw-bold p-2 text-white fw-light text-center w-25" >RSI14</th>
                                                        <th className="fw-bold p-2 text-white fw-light text-center w-25" >Stochastic</th>
                                                        <th className="fw-bold p-2 text-white fw-light text-center w-25" >ROC20</th>
                                                        <th className="fw-bold p-2 text-white fw-light text-center w-25" >CCI20</th>
                                                        <th className="fw-bold p-2 text-white fw-light text-center w-25" >Wi.R14</th>
                                                        <th className="fw-bold p-2 text-white fw-light text-center w-25" >MFI14</th>
                                                        <th className="fw-bold p-2 text-white fw-light text-center w-25">ATR14</th>
                                                        <th className="fw-bold p-2 text-white fw-light text-center w-25" style={{ borderRadius: "0px 10px 10px 0" }}>ADX14</th>
                                           
                                                    </tr>
                                                </thead>
                                                <tbody className="fs-5 fw-light text-white">
                                                    {data?.map((item, index) => (
                                                        <tr key={index} style={{ display: item.PRICECHANGE === 0 ? "none" : "flex" }}>
                                                            <td className="text-white fs-6">
                                                                <span>{item.INSTRUMENTIDENTIFIER.slice(0, -2)}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <div className={`badge rounded-pill w-100 p-2 fs-6`} style={{
                                                                    backgroundColor: item.PRICECHANGEPERCENTAGE > 0 ? "#19C141" : "#F31C1C"
                                                                }}>{item.PRICECHANGEPERCENTAGE}</div>
                                                            </td>
                                                            <td className="text-white text-center fs-6">
                                                                {item.IncatorsSMA10 || 0}
                                                            </td>
                                                            <td className="text-center">
                                                                {item.IncatorsSMA50 || 0}
                                                            </td>
                                                            <td className="text-white text-center fs-6">
                                                                {item.RSI14 || 0}
                                                            </td>
                                                            <td className="text-center">
                                                                {item.Stochastic || 0}
                                                            </td>
                                                            <td className="text-center">
                                                                {item.ROC20 || 0}
                                                            </td>
                                                            <td className="text-center">
                                                                {item.CCI20 || 0}
                                                            </td>
                                                            <td className="text-center">
                                                                {item.WilliamsR14 || 0}
                                                            </td>
                                                            <td className="text-center">
                                                                {item.MFI14 || 0}
                                                            </td>
                                                            <td className="text-center">
                                                                {item.ATR14 || 0}
                                                            </td>
                                                            <td className="text-center">
                                                                {item.ADX14 || 0}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};
MovingAverage.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(MovingAverage);
